import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, Optional, Output, Renderer2, ViewChild } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { isDate } from 'util';

@Component({
  selector: 'gq-input',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputsComponent),
    multi: true
  }]
})
export class InputsComponent implements AfterViewInit, ControlValueAccessor { //, Validator

  @Input() public classInner = "";

  @Input() public label = "";
  @Input() public type = "text";
  @Input() public labelSize = 4;

  @Input() public loading = false;
  @Input() public data: [] | undefined = undefined;
  @Input() public dataLabel: string | Function = "label";
  @Input() public dataValue: string | Function = "id";
  @Input() public dataInput = new Subject<string>();

  @Input() public isValid: boolean | Function = () => { return !(this.validText > "") };
  @Input() public validText = "";

  @Input() public placeholder = "";

  @Input() public dateFormat = "DD/MM/YYYY";

  @Input() public CheckboxValueTrue: string | number | boolean = "1";

  @Input() public CheckboxValueFalse: string | number | boolean = "0";

  @Input() public readonly = false;

  @Input() public posText = "";

  @Input() public preText = "";

  @Input() public icon = "";

  @Input() public fileName = "";

  @Input() public textareaRow = 2;

  @Input() public minDate: NgbDateStruct | undefined = undefined;
  @Input() public maxDate: NgbDateStruct | undefined = undefined;

  @Input() public maskPattern = "";

  @Input() public maxlength = -1;

  //@Input() public specialCharacters: Array<string> = [];

  @Input() public maskpatterns = null;

  @Input() public onFocusSelect = false;

  private _numberFormat = "1.0-4";
  @Input()
  public get numberFormat(): string {
    return this._numberFormat;
  }
  public set numberFormat(value: string) {
    this._numberFormat = value;
    try {
      const sp = value.split("-");
      if (sp.length > 1) {
        if (parseInt(sp[1]) > 0) {
          this.numberType = "decimal";
          return;
        }
      }
    }
    catch (error) { }
    this.numberType = "number";
  }
  public numberType: string | "number" | "decimal" = "decimal";

  @Output() onEnter: EventEmitter<object> = new EventEmitter();

  @Output() change: EventEmitter<object> = new EventEmitter();

  @Output() keyUp: EventEmitter<object> = new EventEmitter();

  @Output() clickIcon: EventEmitter<object> = new EventEmitter();

  onChange = (_: any) => { };
  onTouched = () => { };

  @ViewChild('gqInput', { static: false }) private gqInput: ElementRef<HTMLInputElement>;
  @ViewChild('gqTextArea', { static: false }) private gqTextArea: ElementRef<HTMLTextAreaElement>;
  @ViewChild('gqSelect', { static: false }) private gqSelect: ElementRef<HTMLSelectElement>;
  @ViewChild('gqInputDate', { static: false }) private gqInputDate: NgbDatepicker;
  @ViewChild('gqNgSelect', { static: false }) private gqNgSelect: NgSelectComponent;

  //@ContentChild(NgLabelTemplateDirective, { read: TemplateRef, static: false }) ngSelectLabelTemplate: TemplateRef<any>;
  //@ContentChild(NgOptionTemplateDirective, { read: TemplateRef, static: false }) ngSelectOptionTemplate: TemplateRef<any>;
  //[labelTemplate]="ngSelectLabelTemplate"
  //[optionTemplate] = "ngSelectOptionTemplate"
  /** Whether the user is creating a composition string (IME events). */
  private _composing = false;

  public rndNumberId = "";

  constructor(private _dateParser: NgbDateParserFormatter, private _renderer: Renderer2, private _decimalPipe: DecimalPipe, @Optional() @Inject(COMPOSITION_BUFFER_MODE) private _compositionMode: boolean) {
    if (this._compositionMode === null) {
      this._compositionMode = true;
    }
    this.rndNumberId = (Math.random() * new Date().getTime()).toFixed(0);
  }

  public onFocus(event) {
    if (this.onFocusSelect) {
      event.target.select();
    }
  }

  public _dataLabel(item) {
    if (this.dataLabel instanceof Function) {
      return this.dataLabel(item);
    }
    else if (this.dataLabel > "") {
      return item[this.dataLabel];
    }
    return item;
  }

  public _dataValue(item) {
    if (this.dataValue instanceof Function) {
      return this.dataValue(item);
    }
    else if (this.dataValue > "") {
      return item[this.dataValue];
    }
    return item;
  }

  public isValidCall(): boolean {
    if (this.isValid instanceof Function)
      return this.isValid();
    else
      return this.isValid;
  }

  ngAfterViewInit(): void {
    MomentDateFormatter.DT_FORMAT = this.dateFormat;
    //if (this.gqNgSelect !== undefined && this.gqNgSelect !== null) {
    //  if (this.ngSelectLabelTemplate !== undefined && this.ngSelectLabelTemplate !== null)
    //    this.gqNgSelect.labelTemplate = this.ngSelectLabelTemplate;
    //  if (this.ngSelectOptionTemplate !== undefined && this.ngSelectOptionTemplate !== null)
    //    this.gqNgSelect.optionTemplate = this.ngSelectOptionTemplate;
    //}
    if (this.writeValueNoSet !== undefined) {
      this.writeValue(this.writeValueNoSet);
    }
  }

  public getElement(): any {
    if (this.gqNgSelect !== undefined && this.gqNgSelect !== null && this.type === 'ngselect')
      return this.gqNgSelect;
    if (this.gqSelect !== undefined && this.gqSelect !== null && this.type === 'select')
      return this.gqSelect.nativeElement;
    if (this.gqInputDate !== undefined && this.gqInputDate !== null && this.type === 'date') {
      return this.gqInputDate;
    }
    if (this.gqTextArea !== undefined && this.gqTextArea !== null && this.type === 'textarea')
      return this.gqTextArea.nativeElement;
    if (this.gqInput !== undefined && this.gqInput !== null)
      return this.gqInput.nativeElement;
    return null;
  }
  private writeValueNoSet: string | number | boolean | Date | NgbDate | object | undefined = undefined;

  writeValueFormat(value: string | number | boolean | Date | NgbDate | object): string | number | boolean | Date | NgbDate | object {
    switch (this.type) {
      case "number":
        {
          value = (value === null || value === undefined) ? '' : value;
          const valorFormat = this.numberRemoveFormat(this._decimalPipe.transform(Number(value), this.numberFormat));
          value = parseFloat(valorFormat);
        }
        break;
    }
    return value;
  }

  writeValue(value: string | number | boolean | Date | NgbDate | object): void {
    if (this.getElement() === null) {
      this.writeValueNoSet = value;
      return;
    }

    this.writeValueNoSet = undefined;

    const normalizedValue = (value === null || value === undefined) ? '' : value;

    switch (this.type) {
      case "ngselect":
        {
          this.gqNgSelect.writeValue(value);
        }
        break;
      case "date":
        {
          if (isDate(normalizedValue) && normalizedValue) {
            const date = normalizedValue as Date;
            this.gqInputDate.writeValue(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()));
            //else if (isNgbDateStruct(normalizedValue))
            //  this.gqInputDate.writeValue(normalizedValue as NgbDateStruct);
          }
          else
            this.gqInputDate.writeValue(this._dateParser.parse(normalizedValue.toString()));
        }
        break;
      case "checkbox":
        {
          this.isChecked = normalizedValue === this.CheckboxValueTrue;
        }
        break;
      case "number":
        {
          ///TODO ARREGLO NO MUY BUENO :D
          try {
            const valorFormat = this.numberRemoveFormat(this._decimalPipe.transform(Number(normalizedValue), this.numberFormat));
            this._renderer.setProperty(this.getElement(), 'value', valorFormat);
            value = valorFormat;
          }
          catch (error) {
            this._renderer.setProperty(this.getElement(), 'value', normalizedValue);
          }
        }
        break;
      //case "textarea":
      //  this.gqTextArea.
      default:
        {
          this.fileName = "";
          this._renderer.setProperty(this.getElement(), 'value', normalizedValue);
        }
        break;
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }

  registerOnTouched(fn: any): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    if (this.getElement() === null)
      return;
    if (this.type !== 'date') {
      this._renderer.setProperty(this.getElement(), 'disabled', isDisabled);
    }
    else {
      this.gqInputDate.setDisabledState(isDisabled);
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this._handleInput(event.target.files[0]);
      this.fileName = event.target.files[0].name;
    }
  }

  public isChecked = false;

  onChangeChecked() {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      this.isChecked = !this.isChecked;
      this.onChange(this.isChecked ? this.CheckboxValueTrue : this.CheckboxValueFalse);
      this.change.emit();
    }
  }

  onKeyUp(event) {
    this.keyUp.emit(this.getElement().value.length);
  }

  onChangeDate(date: NgbDateStruct) {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      this.onChange(new Date(date.year, date.month - 1, date.day));
      this.change.emit();
    }
  }

  _onEnter(): void {
    this.onEnter.emit();
  }

  _handleInputNgSelect(value): void {
    if (value !== undefined && value !== null)
      this._handleInput(this._dataValue(value), value);
    else
      this._handleInput(value, value);
  }

  /** @internal */
  _handleInput(value: string | number | boolean | Date | NgbDate | object, originalObject: object | undefined = undefined): void {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      value = this.writeValueFormat(value);
      this.onChange(value);
      this.change.emit(originalObject);
    }
  }

  _handlefocusOut(value: string | number | boolean | Date | NgbDate | object, originalObject: object | undefined = undefined): void {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {

      value = this.writeValueFormat(value);
      this.writeValue(value);
      this.onChange(value);
      this.change.emit(originalObject);
    }
  }

  /** @internal */
  _compositionStart(): void { this._composing = true; }

  /** @internal */
  _compositionEnd(value: string | number | boolean | Date | NgbDate | object): void {
    this._composing = false;
    this._compositionMode && this.onChange(value);
    this.change.emit();
  }

  public onClickIcon() {
    this.clickIcon.emit();
  }

  public numberRemoveFormat(value: string) {
    if (value !== null)
      value = value.replace(/,/gi, "");
    return value;
  }
}

export class MomentDateFormatter extends NgbDateParserFormatter {
  public static DT_FORMAT = 'DD/MM/YYYY';

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, "YYYY-MM-DD")
      return {
        year: mdt.year(),
        month: mdt.month() + 1,
        day: mdt.date(),
      } as NgbDateStruct;
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) return '';
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(MomentDateFormatter.DT_FORMAT);
  }
}
