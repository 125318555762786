import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgActionButtonsComponent, FilterButtons } from './action-buttons.component';
import { GridComponent } from './grid.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AgGridModule.withComponents([AgActionButtonsComponent]),
    NgbModule,
  ],
  declarations: [GridComponent, AgActionButtonsComponent, FilterButtons],
  exports: [GridComponent, AgActionButtonsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [GridComponent, AgActionButtonsComponent],
})
export class GridModule { }
