import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { b64utoutf8, KJUR } from 'jsrsasign';
import { CookieService } from 'ngx-cookie-service';
import { ModelsService } from './model';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private modelService: ModelsService, private cookieService: CookieService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let _this = this;

    if ((this.modelService.User === undefined || _this.modelService.User === null) && _this.cookieService.check(_this.modelService.CookieName)) {
      const token = _this.cookieService.get(_this.modelService.CookieName);
      const headerObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split(".")[0]));
      const payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split(".")[1]));

      if ((payloadObj.exp * 1000) - (new Date()).getTime() <= 0) {
        _this.router.navigate(['/login']);
        return false;
      }

      _this.modelService.exp = payloadObj.exp * 1000;
      _this.modelService.User = JSON.parse(payloadObj["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
      setTimeout(() => {
        this.modelService.EventLoginUser.emit(_this.modelService.User);
      }, 1000);
    }

    if (_this.modelService.User !== undefined && _this.modelService.User !== null) {
      return true;
      /*if (_this.modelService.Accesos != null && _this.modelService.Accesos.length > 0) {

          let className = route.data["className"] as string;
          let methodName = route.data["methodName"] as string;
          ///Revisar esta logica

          methodName = methodName === undefined ? "" : methodName;
          if (className != undefined && className > "") {
              if (_this.modelService.allowController(className)) {
                  if (methodName > "") {
                      return _this.modelService.allowControllerMethod(className, methodName);
                  }
                  return true;
              }
              return false;
          }
          return true;
      }
      else {
          let url = route["_routerState"].url;
          _this.modelService.GetAccesos(_this, _this.cookieService, () => {
              _this.router.navigate([url]);
          });
          return false;
      }*/
    }
    _this.router.navigate(['/login']);
    return false;
  }
}
