import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SelectItem } from './controller/controller.service';

@Injectable()
export class Constantes {

  public static LAYOUT_HOME = "";
  public static LAYOUT_USERS = "usuarios";
  public static LAYOUT_EQUIPOS = "equipos";
  public static LAYOUT_SERVIDORES = "servidores";
  public static LAYOUT_PERFILES = "perfiles";
  public static LAYOUT_FIRMWARES = "firmwares";

  constructor(public t: TranslatePipe) {
  }

  public translate(text: string) {
    return this.t.transform(text);
  }

  public get sexos(): Array<SelectItem> {
    return [
      { id: "M", label: this.translate("default-sexo-m") },
      { id: "F", label: this.translate("default-sexo-f") }
    ];
  }

  public get sexostAll(): Array<SelectItem> {
    return [
      { id: "T", label: this.translate("default-all") },
      { id: "M", label: this.translate("default-sexo-m") },
      { id: "F", label: this.translate("default-sexo-f") }
    ];
  }
}
