import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'gq-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss'],
})
export class BuscarComponent {
  @Input() public title = "";
  @Input() public showSearch = true;
  @Input() public showExport = false;
  @Input() public isOpen = false;
  @Input() public showVolver = false;
  @Input() public showAdd = true;
  
  @Output() onClickSearch: EventEmitter<object> = new EventEmitter();
  @Output() onClickExport: EventEmitter<object> = new EventEmitter();
  @Output() onClickVolver: EventEmitter<object> = new EventEmitter();
  @Output() onClickAdd: EventEmitter<object> = new EventEmitter();

  constructor() {
  }

  public onAdd(): void {
    this.onClickAdd.emit();
  }

  public onOpenClose(): void {
    this.isOpen = !this.isOpen;
  }

  public onSearch(): void {
    this.onClickSearch.emit();
  }

  public onExport(): void {
    this.onClickExport.emit();
  }

  public onVolver(): void {
    this.onClickVolver.emit();
    //debugger;
  }
}
