import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GQModalError, GQModalProgress, GQModalYesNo, ModalComponent } from './modal.component';
import { InputsModule } from '../inputs/inputs.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    InputsModule,
    TranslateModule,
  ],
  providers: [ModalComponent],
  declarations: [ModalComponent, GQModalError, GQModalYesNo, GQModalProgress],
  exports: [ModalComponent, GQModalError, GQModalYesNo, GQModalProgress],
  entryComponents: [ModalComponent, GQModalError, GQModalYesNo, GQModalProgress],
})
export class ModalModule { }
