import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';

import { CookieService } from 'ngx-cookie-service';
import { Constantes } from '../service/constantes';
import { AuthGuard } from '../service/guard/auth.guard';
import { ModelsService } from '../service/guard/model';
import { GQModule } from '../service/modules/gq.module';
import { MomentDateFormatter } from '../service/modules/inputs/inputs.component';
import { ModalModule } from '../service/modules/modals/modal.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const BASE_URL: InjectionToken<string> = new InjectionToken<string>('BASE_URL');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    NgbModule,
    GQModule,
    ModalModule,
    AppRoutingModule,    
  ],
  providers: [
    { provide: BASE_URL, useFactory: getBaseUrl },
    TranslatePipe,
    CookieService,
    AuthGuard,
    ModelsService,
    Constantes,
    {
      provide: NgbDateParserFormatter,
      useFactory: () => { return new MomentDateFormatter() }
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
