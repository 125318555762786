<div class="card-header">
  <div class="pull-left">{{subTitle | translate}}</div>
  <h2 style="display: inline-block;">{{titlePanel | translate}}</h2>
  <button *ngIf="showCancel" class="btn btn-sm btn-warning pull-right" (click)="onCancel()" title="{{'default-cancelar' | translate}}" style="margin-left: 5px !important;"><i class="fa fa-times"></i></button>
  <button *ngIf="showSave" class="btn btn-sm btn-success pull-right" (click)="onSave()" title="{{'default-guardar' | translate}}" style="margin-left: 5px !important;"><i class="fa fa-save"></i></button>
  <button *ngIf="showSaveAndContinue" class="btn btn-sm btn-outline-success pull-right" (click)="onSaveAndContinue()" title="{{'default-guardar_continuar' | translate}}" style="margin-left: 5px !important;"><i class="fa fa-check-circle-o"></i></button>
  <button *ngIf="showExport" type="button" class="btn btn-sm btn-success pull-right" (click)="onExport()" title="{{'default-exportar' | translate}}" style="margin-left: 5px !important;"><i class="fa fa-arrow-circle-down"></i></button>
</div>
<div class="card-body row">
  <ng-content></ng-content>
</div>
