import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { InputsComponent } from './inputs.component';
import { NumericDirective } from './numeric.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    TranslateModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
  ],
  declarations: [InputsComponent, NumericDirective],
  exports: [InputsComponent, NumericDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [InputsComponent],
  providers: [DecimalPipe, NumericDirective]
})
export class InputsModule { }
