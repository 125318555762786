import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'gq-editpanel',
  templateUrl: './editpanel.component.html',
  styleUrls: ['./editpanel.component.scss'],
})
export class EditPanelComponent {
  @Input() public titlePanel = "";
  @Input() public subTitle = "default-edicion";

  @Input() public showCancel = true;
  @Input() public showSave = true;
  @Input() public showSaveAndContinue = false;
  @Input() public showExport = false;

  @Output() onClickCancel: EventEmitter<object> = new EventEmitter();
  @Output() onClickSave: EventEmitter<object> = new EventEmitter();
  @Output() onClickSaveAndContinue: EventEmitter<object> = new EventEmitter();
  @Output() onClickExport: EventEmitter<object> = new EventEmitter();

  constructor() {
  }

  public onCancel(): void {    
    this.onClickCancel.emit();
  }

  public onSave(): void {
    this.onClickSave.emit();
  }

  public onSaveAndContinue(): void {
    this.onClickSaveAndContinue.emit();
  }

  public onExport(): void {
    this.onClickExport.emit();
  }
}
