import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BASE_URL } from '../../app/app.module';

@Injectable()
export class ModelsService {

  public CookieName = "EntalpiaJWT";

  public User: UserLoginDto | undefined = undefined;
  public exp = 0;

  public EventLoginUser: EventEmitter<UserLoginDto> = new EventEmitter<UserLoginDto>();

  protected baseUrl: string;

  constructor(protected http: HttpClient, injector: Injector, protected router: Router,) {
    this.baseUrl = injector.get(BASE_URL);
  }
}

export class UserLoginDto {
  public id = 0;
  public userName = "";
  public name = "";
  public email = "";
  public role = "";
  public accesos: Array<PerfilAccessDto> = [];
}

export class PerfilAccessDto {
  public controller = "";
  public method = "";
  public grantPermition = "";
}
