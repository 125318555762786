import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { BuscarModule } from '../buscar/buscar.module';
import { GridModule } from '../grid/grid.module';
import { BuscarAbmComponent } from './buscarabm.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    NgbModule,
    BuscarModule,
    GridModule,
  ],
  declarations: [BuscarAbmComponent],
  exports: [BuscarAbmComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [BuscarAbmComponent]
})
export class BuscarAbmModule { }
