import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GridModule } from './grid/grid.module';
import { ModalModule } from './modals/modal.module';
import { BuscarModule } from './buscar/buscar.module';
import { BuscarAbmModule } from './buscarabm/buscarabm.module';
import { InputsModule } from './inputs/inputs.module';
import { PageHeaderModule } from './page-header/page-header.module';
import { EditPanelModule } from './editpanel/editpanel.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ModalModule,
    BuscarModule,
    BuscarAbmModule,
    InputsModule,
    PageHeaderModule,
    EditPanelModule,
    GridModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
  entryComponents: [],
  declarations: []
})
export class GQModule { }
