import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GridOptions } from "ag-grid-community";
import { ApiGrid, ClickedEvent, SortChange } from "../grid/grid.component";

@Component({
  selector: 'gq-buscarabm',
  templateUrl: './buscarabm.component.html',
  styleUrls: ['./buscarabm.component.scss'],
})
export class BuscarAbmComponent {
  @Input() public titlePanel = "";

  @Input() public showSearch = true;
  @Input() public showExport = false;
  @Input() public showAdd = true;

  @Input() public showPaging = true;
  @Input() public showGrid = true;

  @Input() public page;
  @Input() public showVolver = false;

  private _gridOptions: GridOptions = null;
  @Input()
  public get gridOptions(): GridOptions { return this._gridOptions; }
  public set gridOptions(value: GridOptions) { this._gridOptions = value; }

  private _columnDefs: Array<ColDef | ColGroupDef> = [];
  @Input()
  public get columnDefs(): Array<ColDef | ColGroupDef> { return this._columnDefs; }
  public set columnDefs(value: Array<ColDef | ColGroupDef>) { this._columnDefs = value; }

  private _showLoadingOverlay = false;
  @Input()
  public get showLoadingOverlay(): boolean { return this._showLoadingOverlay; }
  public set showLoadingOverlay(value: boolean) { this._showLoadingOverlay = value; }

  private _rowClassRules: (params) => (string | string[]);
  @Input()
  public get rowClassRules(): (params) => (string | string[]) { return this._rowClassRules; }
  public set rowClassRules(value: (params) => (string | string[])) { this._rowClassRules = value; }

  @Output() onClickSearch: EventEmitter<object> = new EventEmitter();
  @Output() onSearch: EventEmitter<object> = new EventEmitter();
  @Output() onClickExport: EventEmitter<object> = new EventEmitter();
  @Output() onClickAdd: EventEmitter<object> = new EventEmitter();
  @Output() onClickVolver: EventEmitter<object> = new EventEmitter();

  @Output() public onGridReady: EventEmitter<ApiGrid> = new EventEmitter();
  @Output() public onSortChanged: EventEmitter<SortChange> = new EventEmitter();
  @Output() public onRowClicked: EventEmitter<ClickedEvent> = new EventEmitter();

  constructor() {
  }

  public onAdd(): void {
    this.onClickAdd.emit();
  }

  public onExport(): void {
    this.onClickExport.emit();
  }

  public onVolver(): void {
    this.onClickVolver.emit();
  }

  public _onGridReady(params: ApiGrid) {
    this.onGridReady.emit(params);
  }

  public _onSortChanged(params: SortChange) {
    this.onSortChanged.emit(params);
  }

  public _onRowClicked(params: ClickedEvent) {
    this.onRowClicked.emit(params);
  }

  public _onClickSearch(): void {
    this.onClickSearch.emit();
  }

  public _onSearch(): void {
    this.onSearch.emit(this.page);
  }
}
@Component({
  selector: 'check-cell-renderer',
  template: `<input type='checkbox' [checked]='params.value==1' (change)='onChange()'>`
})
export class CheckCellRenderer implements ICellRendererAngularComp {
  public params;

  agInit(params: any): void {
    this.params = params;
  }

  onChange() {
    this.params.setValue(this.params.value === 1 ? 0 : 1);
  }

  refresh(): boolean {
    return false;
  }
}
