<div class="row barElement" style="display:block; padding:0 !important; margin:0 !important; padding-bottom:5px !important; ">
  <h2 style="display: inline-block;">{{title | translate}}</h2>
  <button *ngIf="showVolver" class="btn btn-sm btn-success pull-right" (click)="onVolver()" title="{{'default-volver' | translate}}"><i class="fa fa-arrow-circle-left"></i></button>
  <button *ngIf="showExport" class="btn btn-sm btn-success pull-right" (click)="onExport()" title="{{'default-exportar' | translate}}"><i class="fa fa-arrow-circle-down"></i></button>
  <button *ngIf="showAdd" class="btn btn-sm btn-success pull-right" (click)="onAdd()" title="{{'default-agregar' | translate}}"><i class="fa fa-plus"></i></button>
  <button *ngIf="showSearch" class="btn btn-sm btn-secondary pull-right" (click)="onOpenClose()" title="{{'default-avanzada' | translate}}"><i class="fas fa-filter"></i></button>
  <button *ngIf="showSearch" class="btn btn-sm btn-primary pull-right" (click)="onSearch()" title="{{'default-buscar' | translate}}"><i class="fa fa-search"></i></button>
</div>
<div *ngIf="isOpen" class="card2">
  <ng-content></ng-content>
</div>
