import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EditPanelComponent } from './editpanel.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [EditPanelComponent],
  exports: [EditPanelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [EditPanelComponent]
})
export class EditPanelModule { }
