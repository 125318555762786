import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BuscarComponent } from './buscar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [BuscarComponent],
  exports: [BuscarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [BuscarComponent]
})
export class BuscarModule { }
