import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export class GQModal {
  constructor(public activeModal: NgbActiveModal, public _sanitizer: DomSanitizer) { }

  public bypassSecurityTrustStyle(value) {
    return this._sanitizer.bypassSecurityTrustStyle(value);
  }

  public bypassSecurityTrustHtml(value) {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'gq-modal-error',
  template: `
    <div class="modal-header" [class.bg-danger]="isError" [class.bg-info]="!isError"  style="color:white">
      <h4 class="modal-title">{{title | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(result_cerrar)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHtml]="bypassSecurityTrustHtml(message)"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(result_cerrar)">{{'default-cerrar' | translate}}</button>
    </div>
  `
})
export class GQModalError extends GQModal {
  public static RESULT_CERRAR = "cerrar";
  public result_cerrar = GQModalError.RESULT_CERRAR;

  @Input() title = "";
  @Input() message = "";
  @Input() isError = false;

  constructor(public activeModal: NgbActiveModal, public _sanitizer: DomSanitizer) {
    super(activeModal, _sanitizer);
  }
}

@Component({
  selector: 'gq-modal-yes-no',
  template: `
    <div class="modal-header bg-primary" style="color:white">
      <h4 class="modal-title">{{title | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(result_no)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHtml]="bypassSecurityTrustHtml(message)"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(result_si)">{{'default-si' | translate}}</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(result_no)">{{'default-no' | translate}}</button>
    </div>
  `
})
export class GQModalYesNo extends GQModal {
  public static RESULT_SI = "si";
  public static RESULT_NO = "no";

  public result_si = GQModalYesNo.RESULT_SI;
  public result_no = GQModalYesNo.RESULT_NO;

  @Input() title = "";
  @Input() message = "";
  constructor(public activeModal: NgbActiveModal, public _sanitizer: DomSanitizer) {
    super(activeModal, _sanitizer);
  }
}

@Component({
  selector: 'gq-modal-progress',
  template: `
    <div *ngIf="title>''" class="modal-header">
      <h4 class="modal-title">{{title | translate}}</h4>
    </div>
    <div class="modal-body" style="min-height: 100px;
    overflow-y: auto;">
      <p *ngIf="message>''" [innerHtml]="bypassSecurityTrustHtml(message)"></p>
      <div class="progress">
        <div class="progress-bar progress-bar-success progress-bar-striped progress-bar-animated active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
      </div>
    </div>
  `
})
export class GQModalProgress extends GQModal {

  @Input() title = "";
  @Input() message = "";
  constructor(public activeModal: NgbActiveModal, public _sanitizer: DomSanitizer) {
    super(activeModal, _sanitizer);
  }
}

@Component({
  selector: 'gq-modal',
  template: ''
})
export class ModalComponent {
  public static RESULT_SI = GQModalYesNo.RESULT_SI;
  public static RESULT_NO = GQModalYesNo.RESULT_NO;
  public static RESULT_CERRAR = GQModalError.RESULT_CERRAR;

  constructor(private modalService: NgbModal) { }

  public openGeneric(modal, config: NgbModalOptions | undefined = undefined): NgbModalRef {
    if (config === undefined) {
      config = { backdrop: "static", keyboard: false, centered: true, size: "sm" } as NgbModalOptions;
    }
    return this.modalService.open(modal, config);
  }

  public openGenericExt(modal, valor: string, config: NgbModalOptions | undefined = undefined): Promise<any> {
    if (config === undefined) {
      config = { backdrop: "static", keyboard: false, centered: true, size: "sm" } as NgbModalOptions;
    }
    const modalRef = this.modalService.open(modal, config as NgbModalOptions);

    modalRef.componentInstance.valor = valor;
    alert(valor);
    return modalRef.result;
  }

  public openError(message: string, title: string | undefined = undefined, options: NgbModalOptions | undefined = undefined): Promise<any> {
    title = title === undefined ? "Error" : title;
    options = options === undefined ? { backdrop: "static", keyboard: false, centered: true, size: "sm" } as NgbModalOptions : options;

    const modalRef = this.modalService.open(GQModalError, options as NgbModalOptions);

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isError = true;

    return modalRef.result;
  }

  public openDialog(message: string, title: string | undefined = undefined, options: NgbModalOptions | undefined = undefined): Promise<any> {
    title = title === undefined ? "" : title;
    options = options === undefined ? { backdrop: "static", keyboard: false, centered: true, size: "sm" } as NgbModalOptions : options;

    const modalRef = this.modalService.open(GQModalError, options as NgbModalOptions);

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isError = false;

    return modalRef.result;
  }

  public openYesNo(message: string, title: string | undefined = undefined, options: NgbModalOptions | undefined = undefined): Promise<any> {
    title = title === undefined ? "" : title;
    options = options === undefined ? { backdrop: "static", keyboard: false, centered: true, size: "sm" } as NgbModalOptions : options;

    const modalRef = this.modalService.open(GQModalYesNo, options as NgbModalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;

    return modalRef.result;
  }

  public static progressModalRef: NgbModalRef = null;

  public openProgress(message: string | undefined = undefined, title: string | undefined = undefined, options: NgbModalOptions | undefined = undefined): Promise<any> {

    if (ModalComponent.progressModalRef === null) {
      title = title === undefined ? "" : title;
      message = message === undefined || message === '' ? "Procesando petición..." : message;
      options = options === undefined ? { keyboard: false, centered: true, size: "sm", backdrop: 'static' } as NgbModalOptions : options;

      const modalRef = this.modalService.open(GQModalProgress, options as NgbModalOptions);
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      ModalComponent.progressModalRef = modalRef;
      return modalRef.result;
    }
    return ModalComponent.progressModalRef.result;
  }

  public closeProgress(): void {
    try {
      if (ModalComponent.progressModalRef !== null)
        ModalComponent.progressModalRef.close();
    } catch (e) {
    }
    ModalComponent.progressModalRef = null;
  }

  public static progressLoadModalRef: NgbModalRef = null;

  public openLoadPage(message: string | undefined = undefined, title: string | undefined = undefined, options: NgbModalOptions | undefined = undefined): Promise<any> {

    if (ModalComponent.progressLoadModalRef === null) {
      title = title === undefined ? "" : title;
      message = message === undefined || message === '' ? "Cargando pagina..." : message;
      options = options === undefined ? { keyboard: false, centered: true, size: "sm", backdrop: 'static' } as NgbModalOptions : options;

      const modalRef = this.modalService.open(GQModalProgress, options as NgbModalOptions);
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      ModalComponent.progressLoadModalRef = modalRef;
      return modalRef.result;
    }
    return ModalComponent.progressLoadModalRef.result;
  }

  public closeLoadPage(): void {
    try {
      if (ModalComponent.progressLoadModalRef !== null)
        ModalComponent.progressLoadModalRef.close();
    } catch (e) {
    }
    ModalComponent.progressLoadModalRef = null;
  }
}
