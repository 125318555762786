<div class="row {{classInner}}" style="margin-top:2.5px;margin-bottom:2.5px;" [ngSwitch]="type">
  <label *ngIf="labelSize!==0"
         [style.justify-content]="labelSize==12?'center':'right'"
         [style.display]="labelSize==12?'flex':'inline-block'"
         [style.text-align]="labelSize==12?'center':'right'"
         [ngClass]="{'col-form-label':labelSize!==12}"
         class="col-{{labelSize}}">{{label | translate}}</label>
  <div class="input-group col-{{(12-labelSize==0?12:12-labelSize)}} " [ngClass]="{'is-invalid':!isValidCall()}">
    <div *ngIf="preText>''" class="input-group-append">
      <span class="input-group-text">{{preText}}</span>
    </div>
    <ng-select #gqNgSelect *ngSwitchCase="'ngselect'"
               class="form-control custom"
               [ngClass]="{'is-invalid':!isValidCall()}"
               [items]="data | async"
               [loading]="loading"
               [typeahead]="dataInput"
               [bindLabel]="dataLabel"
               [bindValue]="dataValue"
               [placeholder]="placeholder"
               [minTermLength]="3"
               (blur)="onTouched()"
               (change)="_handleInputNgSelect($event)"
               (compositionstart)="_compositionStart()"
               (compositionend)="_compositionEnd($event.target.value)"
               (keydown.enter)="_onEnter()"
               [disabled]="readonly">
    </ng-select>
    <select #gqSelect *ngSwitchCase="'select'"
            class="form-control"
            [ngClass]="{'is-invalid':!isValidCall()}"
            (change)="_handleInput($event.target.value)"
            (blur)="onTouched()"
            (compositionstart)="_compositionStart()"
            (compositionend)="_compositionEnd($event.target.value)"
            (keydown.enter)="_onEnter()"
            [disabled]="readonly">
      <option *ngFor="let item of data" [value]="_dataValue(item)">
        {{_dataLabel(item)}}
      </option>
    </select>
    <div class="form-control select-editable" *ngSwitchCase="'selectEdit'">
      <select onchange="this.nextElementSibling.value=this.value"
              (change)="_handleInput($event.target.value)"
              class="form-control"
              (keydown.enter)="_onEnter()"
              [ngClass]="{'is-invalid':!isValidCall()}">
        <option *ngFor="let item of data" [value]="_dataValue(item)">
          {{_dataLabel(item)}}
        </option>
      </select>
      <input #gqInput
             type="text"
             placeholder="{{placeholder}}"
             class="form-control"
             [ngClass]="{'is-invalid':!isValidCall()}"
             (input)="_handleInput($event.target.value)"
             (blur)="onTouched()"
             (compositionstart)="_compositionStart()"
             (compositionend)="_compositionEnd($event.target.value)"
             (keydown.enter)="_onEnter()"
             [readonly]="readonly"
             [attr.maxlength]="maxLength" />
    </div>
    <input *ngSwitchCase="'date'" #gqInputDate="ngbDatepicker" placeholder="{{placeholder}}"
           class="form-control"
           [maxDate]="maxDate"
           [minDate]="minDate"
           [ngClass]="{'is-invalid':!isValidCall()}"
           (input)="_handleInput($event.target.value)"
           (blur)="onTouched()"
           (compositionstart)="_compositionStart()"
           (compositionend)="_compositionEnd($event.target.value)"
           (dateSelect)="onChangeDate($event)"
           (keydown.enter)="_onEnter()"
           [readonly]="true"
           ngbDatepicker>
    <div *ngSwitchCase="'date'" class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="gqInputDate.toggle()" type="button" [disabled]="readonly">
        <i class="fa fa-calendar"></i>
      </button>
    </div>
    <div *ngSwitchCase="'checkbox'" class="input-group-prepend panel">
      <div class="input-group-text">
        <input #gqInput
               type="checkbox"
               (input)="_handleInput($event.target.value)"
               (blur)="onTouched()"
               [checked]="isChecked"
               (compositionstart)="_compositionStart()"
               (compositionend)="_compositionEnd($event.target.value)"
               (change)="onChangeChecked()"
               (keydown.enter)="_onEnter()"
               [readonly]="readonly"
               [disabled]="readonly" />
      </div>
    </div>
    <label *ngSwitchCase="'checkbox'" class="form-control"></label>
    <div *ngSwitchCase="'onlycheckbox'" class="input-group-prepend panel">
      <div class="input-group-text">
        <input #gqInput
               type="checkbox"
               (input)="_handleInput($event.target.value)"
               (blur)="onTouched()"
               [checked]="isChecked"
               (compositionstart)="_compositionStart()"
               (compositionend)="_compositionEnd($event.target.value)"
               (change)="onChangeChecked()"
               (keydown.enter)="_onEnter()"
               [readonly]="readonly"
               [disabled]="readonly" />

      </div>
      <select #gqSelect *ngSwitchCase="'onlycheckbox'"
              class="form-control"
              [ngClass]="{'is-invalid':!isValidCall()}"
              (change)="_handleInput($event.target.value)"
              (blur)="onTouched()"
              (compositionstart)="_compositionStart()"
              (compositionend)="_compositionEnd($event.target.value)"
              (keydown.enter)="_onEnter()"
              [disabled]="readonly">
        <option *ngFor="let item of data" value="{{(dataValue>''?item[dataValue]:item)}}">
          {{(dataLabel>''?item[dataLabel]:item)}}
        </option>
      </select>
    </div>
    <textarea #gqTextArea *ngSwitchCase="'textarea'"
              placeholder="{{placeholder}}"
              class="form-control"
              rows="{{textareaRow}}"
              [ngClass]="{'is-invalid':!isValidCall()}"
              (change)="_handleInput($event.target.value)"
              (blur)="onTouched()"
              (compositionstart)="_compositionStart()"
              (compositionend)="_compositionEnd($event.target.value)"
              (keyup) ="onKeyUp($event)"
              (keydown.enter)="_onEnter()"
              [readonly]="readonly"
              [attr.maxlength]="maxLength">
    </textarea>
    <div class="custom-file" *ngSwitchCase="'file'">
      <input #gqInput
             id="inputGroupFile{{rndNumberId}}"
             type="file"
             placeholder="{{placeholder}}"
             class="custom-file-input"
             [ngClass]="{'is-invalid':!isValidCall()}"
             (input)="_handleInput($event.target.value)"
             (blur)="onTouched()"
             (change)="onFileChange($event)"
             (compositionstart)="_compositionStart()"
             (compositionend)="_compositionEnd($event.target.value)"
             (keydown.enter)="_onEnter()"
             [readonly]="readonly" />
      <label class="custom-file-label" for="inputGroupFile{{rndNumberId}}">{{fileName}}</label>
    </div>
    <input #gqInput *ngSwitchCase="'number'"
           (click) ="onFocus($event)"
           type="text"
           [numeric]="numberType"
           placeholder="{{placeholder}}"
           class="form-control"
           [ngClass]="{'is-invalid':!isValidCall()}"
           (input)="_handleInput($event.target.value)"
           (focusout)="_handlefocusOut($event.target.value)"
           (blur)="onTouched()"
           (compositionstart)="_compositionStart()"
           (compositionend)="_compositionEnd($event.target.value)"
           (keydown.enter)="_onEnter()"
           [readonly]="readonly"
           style="text-align:right;"
           [attr.maxlength]="maxLength" />
    <input #gqInput *ngSwitchCase="'mask'"
           (click) ="onFocus($event)"
           type="text"
           mask="{{maskPattern}}"
           placeholder="{{placeholder}}"
           class="form-control"
           [ngClass]="{'is-invalid':!isValidCall()}"
           (input)="_handleInput($event.target.value)"
           (blur)="onTouched()"
           (compositionstart)="_compositionStart()"
           (compositionend)="_compositionEnd($event.target.value)"
           (keydown.enter)="_onEnter()"
           [readonly]="readonly"
           [attr.maxlength]="maxLength"
           [patterns]="maskpatterns"           
           />           
    <input #gqInput *ngSwitchDefault
           (click) ="onFocus($event)"
           type="{{type}}"
           placeholder="{{placeholder}}"
           class="form-control"
           [ngClass]="{'is-invalid':!isValidCall()}"
           (input)="_handleInput($event.target.value)"
           (blur)="onTouched()"
           (compositionstart)="_compositionStart()"
           (compositionend)="_compositionEnd($event.target.value)"
           (keydown.enter)="_onEnter()"
           [readonly]="readonly"
           [attr.maxlength]="maxLength" />
    <div *ngIf="posText>''" class="input-group-append">
      <span class="input-group-text">{{posText}}</span>
    </div>
    <div *ngIf="icon>''" class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="onClickIcon()" type="button">
        <i [ngClass]="icon"></i>
      </button>
    </div>
    <div *ngIf="validText!=''" class="invalid-feedback">
      {{validText}}
    </div>
  </div>
</div>
