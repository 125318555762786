import { Component, DoCheck, EventEmitter, HostListener, Input, IterableDiffers, OnInit, Output } from '@angular/core';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, SortChangedEvent } from 'ag-grid-community';
import { AgActionButtonsComponent } from './action-buttons.component';

@Component({
  selector: 'gq-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, DoCheck {

  public sort: { colId: string, sort: string }[];

  private _gridOptions: GridOptions = null;

  iterableDiffer: any;

  @Input()
  public get gridOptions(): GridOptions { return this._gridOptions; }
  public set gridOptions(value: GridOptions) { this._gridOptions = value; }

  private _columnDefs: Array<ColDef | ColGroupDef> = [];
  @Input()
  public get columnDefs(): Array<ColDef | ColGroupDef> { return this._columnDefs; }
  public set columnDefs(value: Array<ColDef | ColGroupDef>) { this._columnDefs = value; this.setColumnns(); }

  private _data: Array<object> = [];
  @Input()
  public get data(): Array<object> { return this._data; }
  public set data(value: Array<object>) { this._data = value; this.setDataRow(); }

  private _showLoadingOverlay = false;
  @Input()
  public get showLoadingOverlay(): boolean { return this._showLoadingOverlay; }
  public set showLoadingOverlay(value: boolean) { this._showLoadingOverlay = value; this.setShowLoadingOverlay(); }

  private _rowClassRules: (params) => (string | string[]);
  @Input()
  public get rowClassRules(): (params) => (string | string[]) { return this._rowClassRules; }
  public set rowClassRules(value: (params) => (string | string[])) { this._rowClassRules = value; }

  @Output() public onReady: EventEmitter<ApiGrid> = new EventEmitter();
  @Output() public onSortChanged: EventEmitter<SortChange> = new EventEmitter();
  @Output() public onRowClicked: EventEmitter<ClickedEvent> = new EventEmitter();

  private api: GridApi = null;
  private columnApi: ColumnApi = null;

  public constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.data);
    if (changes) {
      this.setDataRow();
    }
  }

  public onRowClickedInternal(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const actionType = e.event.target.getAttribute("data-action-type") === null ? e.event.target["action-type"] : e.event.target.getAttribute("data-action-type");
      this.onRowClicked.emit({ action: actionType, data: data } as ClickedEvent);
      this.api.redrawRows();
    }
  }

  public onReadyGrid(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.setColumnns();
    this.setDataRow();
    this.setShowLoadingOverlay();

    this.onReady.emit({ api: this.api, columnApi: this.columnApi } as ApiGrid);

    this.api.sizeColumnsToFit();
  }

  private setColumnns() {
    if (this.api !== null) {
      this.api.setColumnDefs(this._columnDefs);
    }
  }

  private setDataRow() {
    if (this.api !== null) {
      this.api.setRowData(this._data);
      this.api.sizeColumnsToFit();
    }
  }

  private setShowLoadingOverlay() {
    if (this.api !== null) {
      if (this._showLoadingOverlay)
        this.api.showLoadingOverlay();
      else
        this.api.hideOverlay();
    }
  }

  ngOnInit(): void {
    if (this._gridOptions === null) {
      this.gridOptions = {
        rowData: this._data,
        rowHeight: 32,
        getRowClass: (param) => {
          return this.rowClassRules instanceof Function ? this.rowClassRules(param) : "";
        },
        columnDefs: this._columnDefs,
        context: {
          componentParent: this
        },
        frameworkComponents: {
          actionButtons: AgActionButtonsComponent,
        },
        onSortChanged: (e) => { this.sort = this.api.getSortModel(); this.onSortChanged.emit({ e: e, api: this.api, columnApi: this.columnApi, sort: this.sort } as SortChange); },
        onRowClicked: (e) => { this.onRowClickedInternal(e); }
      } as GridOptions;
    }
    this.onWindowsResize();
  }

  private timeout = null;
  @HostListener('window:resize', ['$event'])
  private onWindowsResize() {
    if (this.api !== null) {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.api !== undefined && this.api !== null) {
          this.api.sizeColumnsToFit();
        }
        this.timeout = null;
      }, 500);
    }
  }
}

export interface ClickedEvent {
  action: string;
  data;
}

export interface ApiGrid {
  api: GridApi;
  columnApi: ColumnApi;
}

export interface SortChange extends ApiGrid {
  e: SortChangedEvent;
  sort: Array<SortItem>;
}

export interface SortItem {
  colId: string;
  sort: string;
}
