<div class="card-header">
  <gq-buscar (onClickSearch)="_onClickSearch()" (onClickExport)="onExport()" (onClickVolver)="onVolver()" (onClickAdd)="onAdd()"
             [showSearch]="showSearch" [showAdd]="showAdd" [showExport]="showExport" [title]="titlePanel" [showVolver]="showVolver">
    <ng-content></ng-content>
  </gq-buscar>
</div>
<div class="card-body" style="padding:unset;">
  <gq-grid *ngIf="showGrid"
           [data]="page.data"
           [gridOptions]="gridOptions"
           [columnDefs]="columnDefs"
           [showLoadingOverlay]="showLoadingOverlay"
           [rowClassRules]="rowClassRules"
           (onReady)="_onGridReady($event)"
           (onSortChanged)="_onSortChanged($event)"
           (onRowClicked)="_onRowClicked($event)"
           class="ag-theme-balham"></gq-grid>
  <div class="row" style="align-content: center;justify-content: center;">
    <ngb-pagination *ngIf="showPaging" [collectionSize]="page.recordCount" [pageSize]="page.pageSize" [(page)]="page.pageIndex" [maxSize]="10" [rotate]="true" [boundaryLinks]="true" (pageChange)="_onSearch()" size="sm" class="d-flex justify-content-center"></ngb-pagination>
  </div>
</div>
